
<template>
    <div>
         <!-- fetch-user-bulk-voucher-request/ -->
        <div class="holder">
            <div class="container-fluid">
                <p @click="backBtn()" class="back-text"><span><i class="fa fa-chevron-left"></i></span>Back</p>
                <div class="top-text">
                    <h4>Bulk vouchers requests</h4>
                    <p>Track and manage all your vouchers in one place</p>
                </div>

                <!-- <div class="vouchers-buttons-types">
                    <button :class="tab == 1 ? 'activeTab' : ''" class="btn" @click="tab = 1; fetchAllVouchers()">All</button>
                    <button :class="tab == 2 ? 'activeTab' : ''" class="btn" @click="tab = 2; fetchAllVouchers()">Active</button>
                    <button :class="tab == 3 ? 'activeTab' : ''" class="btn" @click="tab = 3; fetchAllVouchers()">Used</button>
                </div> -->

                <div class="table-responsive voucherListTable">
                    <div class="m-auto text-center mt-4" v-if="loading"><Loader/></div>
                    <table class="table" v-if="!loading && vouchers.length != 0">
                        <thead>
                            <tr>
                                <th scope="col">Voucher type</th>
                                <th scope="col">Amount</th>
                                <th scope="col">Quantity</th>
                                <th scope="col">Date created</th>
                                <th scope="col">Date requested</th>
                                <th scope="col">Satus</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr  v-for="(item, index) in vouchers"
                                :key="index"
                                :value="item.id">
                            <td>{{ item.type }}</td>
                            <td>{{ Number(item.amount).toLocaleString() }} </td>
                            <td>{{ item.quantity }}</td>
                            <td>{{ item.created_at | moment }}</td>
                            <td>{{ item.requested_date | moment }}</td>
                            <td>{{ item.status }}</td>
                            
                            <!-- <td>
                                <img class="dropdown-toggle" style="cursor: pointer;" src="../assets/icons/menu-icon.svg" alt="menu" data-toggle="dropdown" aria-expanded="false">
                                <div class="dropdown-menu">
                                <a @click="viewLandlordDetails(item.id)" class="dropdown-item">View details</a>
                                <a @click="addProperty(item.id)" class="dropdown-item">Add property (unit)</a>
                                <a class="dropdown-item">Generate report</a>
                                <a class="dropdown-item">Delete</a>
                                </div>
                            </td> -->
                            </tr>
                        </tbody>
                    </table>
                    <div v-if="vouchers.length == 0 && !loading">
                    <EmptyState :emptyData="emptyDataObject" />
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>


<script>
// import transactionPin from "../services/transactionPin";

import generatingVoucher from '../services/voucher';
import EmptyState from "../components/EmptyState.vue";
import Loader from '../components/Loader';
import moment from 'moment';
// import $ from "jquery";

export default {
   name: "BulkRequest",
   components: {
        Loader,
        EmptyState
   },
    data() {
      return {
        loading: false,
        tab: 1,
        vouchers: [],
        // voucherTypes: [],
        typeValue: "",
        voucherTypeId: "",
        createdDate: "",
        startDate: "",
        endDate: "",
        typeName: "",
        codeSearch: "",
        emptyDataObject: {
          header: 'No recode found',
          option: 'Go to dashboard, click on the generate voucher button to generate voucher'
        },
        today: new Date().toISOString().split("T")[0], // Gets today's date in YYYY-MM-DD format
      }
    },
    // computed: {
    //     formattedInput() {
    //       return this.formatWithHyphens(this.codeSearch);
    //     },
    // },
    // watch: {
    //     codeSearch(newValue) {
    //      if (newValue.length === 16) {
    //         return this.fetchAllVouchersWithFilter();
    //      }
    //      if(newValue == '') {
    //         this.fetchAllVouchers();
    //      }
    //  },
    //  deep: true,
    // },
    mounted() {
        this.fetchAllBulkVouchers();
    },
    methods: {
        // resetFilter() {
        //     this.startDate = "";
        //     this.endDate = "";
        //     this.voucherTypeId = "";
        //     this.typeValue = "";

        // },
        // formatInputRemoveCharacters(event) {
        //     let value = event.target.value.replace(/[^0-9]/g, ""); // Remove non-numeric values
        //     value = value.slice(0, 16); // Limit to 16 digits (without hyphens)
        //     this.codeSearch = value; // Update the raw input
        // },
        // formatWithHyphens(value) {
        //     return value.replace(/(\d{4})(?=\d)/g, "$1-"); // Add hyphen after every 4 digits except last
        // },
        
        // datechange() {
        //     this.tab = 1;
        //     this.fetchAllVouchersWithFilter();
        // },
        // applyFilter() {
        //     if (this.startDate == '' || this.endDate == '' || this.voucherTypeId == '') {
        //         return this.$toast.error("Please selectt all fields");
        //     }
        //     this.tab = 1;
        //     this.fetchAllVouchersWithFilter()
        // },
        backBtn() {
            this.$router.push({path: `/user/my-vouchers`});
        },
        fetchAllBulkVouchers() {
            this.loading = true;
            generatingVoucher.allBulkVouchersRequests().then(res => {
                // console.log("Vlouchers", res);
                this.loading = false;
               if (res.success) {
                this.vouchers = res.data;
               }
            }).catch((err) => {
                this.loading = false;
                console.log(err);
            });
        },
   },
   filters: {
        moment: function(date) {
        return moment(date).format('MMMM Do YYYY');
        }
        // moment: function(date) {
        //   return moment(date).format('MMMM DD YYYY, h:mm:ss a');
        // }
    },
    immediate: true
}
</script>


<style scoped lang="scss">

$primary: #0033ea;
$white: #ffffff;
$yellow: #ffb100;
$asheGrey: #555C74;
$offblack: #2B3352;

$shelta-black: #000a2f;
$shelta-gray: #555c74;

$secondary: #ffffff;
$black: #000000;
$dline: #dddddd;

.holder {
    background: #ffffff;
    padding: 30px 0px;
}

.back-text {
   font-family: Lato;
   font-weight: 600;
   font-size: 16px;
   line-height: 24px;
   color: #535862;
   margin-bottom: 20px !important;
   cursor: pointer;
   span {
      padding-right: 10px;
   }
}

.top-text {
    h4 {
        font-family: Lato;
        font-weight: 600;
        font-size: 24px;
        line-height: 32px;
        color: #2B3352;
    }
    p {
        font-family: Lato;
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
        color: #555C74;

    }
}

.vouchers-buttons-types {
    background: #FAFAFA;
    width: fit-content;
    margin-top: 20px;
    button {
        background: #FAFAFA;
        color: #555C74;
        font-family: Lato;
        font-weight: 600;
        font-size: 14px;
        width: 147px;
        min-height: 36px;
        border: 1px solid #E9EAEB;
        border-radius: 0;
        outline: 0;
        box-shadow: none;
    }
}

.activeTab {
    background: #FFFFFF !important;
    border: 1px solid #D5D7DA !important;
    border-radius: 5px !important;
}


.voucherListTable {
    margin-top: 30px;
    margin-bottom: 20px;
    border: 2px solid #EAECF0;
    border-radius: 20px;
}


.table thead th {
    border-bottom: none;
    
}
.table td {
    border-top: 1px solid #EAECF0;
}
.table th {
    border-top: none;
}

thead > tr {
    background: #EAECF0;
}
thead th {
    white-space: nowrap;
    font-family: Lato;
    font-size: 12px;
    font-weight: 500;
    line-height: 18px;
    text-align: left;
    color: #667085
}

tbody td {
    padding: 18px .75rem;
    white-space: nowrap;
    font-family: Lato;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    text-align: left;
    color: #667085
}

.activeVoucher {
    font-family: Lato;
    font-weight: 500;
    font-size: 12px;
    line-height: 18px;
    color: #067647;
    background: #ECFDF3;
    border-radius: 50px;
    padding: 4px 8px;
    // text-align: center;

}
.nonActiveVoucher {
    font-family: Lato;
    font-weight: 500;
    font-size: 12px;
    line-height: 18px;
    // text-align: center;
    color: #555C74;
    background: #FAFAFA;
    border-radius: 50px;
    padding: 4px 8px;
}

.filter-holder {
    margin-top: 30px;
}

.input-field {
    height: 48px;
    outline: 0;
    box-shadow: none;
}

.search {
   width: 70%; 
}

// .filter-side {
//     // display: flex;
//     // justify-content: space-between;
// }
// .date-type-holder {
//     // display: flex;
//     // justify-content: space-between;
// }

.voucherCancel, .voucherPay {
  width: 45% !important;
}
.voucherCancel, .voucherPay {
  width: 45% !important;
}
.modal-button {
  // padding-top: 24px;
  // padding-bottom: 40px;
  padding-top: 15px;
  padding-bottom: 20px;
  display: flex;
  justify-content: space-between;
  border-top: none !important;

  .cancel {
    border-radius: 6px;
    border: 1px solid $dline;
    color: $shelta-black;
    width: 178px;
    height: 48px;
    text-align: center;
    font-family: Lato;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    outline: 0;
    box-shadow: none;
  }

  .continue {
    border-radius: 6px;
    background: $primary;
    color: $secondary;
    // width: 178px;
    min-height: 48px;
    text-align: center;
    font-family: Lato;
    font-size: 16px;
    font-style: normal;
    outline: 0;
    box-shadow: none;
  }
}
@media screen and (max-width: 599px) {
    .vouchers-buttons-types  {
        button {
            // width: 86px;
            width: 98px;
        }
    }
    .search {
        width: 100%; 
    }
    #select-date {
        margin-bottom: 10px;
    }

    .select-date2 {
        margin-bottom: 10px;
    }
}


</style>