import client from "./requestclient";

const generatingVoucher = {
    generateVoucher: async (voucherPayload, url) => {
        return await client({ URL_PATH: `${url}`, method: "POST", body: voucherPayload });
    },

    voucherTypes: async () => {
      return await client({ URL_PATH: `fetch-voucher-types`, method: "GET" });
    },

    allVouchers: async (url) => {
        return await client({ URL_PATH: `${url}`, method: "GET" });
    },

    allBulkVouchersRequests: async () => {
      return await client({ URL_PATH: `fetch-user-bulk-voucher-request`, method: "GET" });
    },

    uploadTransferReceipt: async (formData) => {
        return await client({
          URL_PATH: "uploadimportantfile",
          method: "POST",
          body: formData, 
          bodyType: "FormData"
        });
    },
    voucverDiscount: async (object) => {
        return await client({
          URL_PATH: "compute-bulk-voucher-discount",
          method: "POST",
          body: object,
        });
    },
    // generateStandardVoucher: async (voucherPayload) => {
    //   return await client({ URL_PATH: `generate-voucher2`, method: "POST", body: voucherPayload });
    // },
    // generateBulkVoucher: async (voucherPayload) => {
    //   return await client({ URL_PATH: `voucher-bulk-buy2`, method: "POST", body: voucherPayload });
    // },
}

export default generatingVoucher;
